import { useTranslation } from 'react-i18next';

import TabbedDesigner from '~components/functional-designer/TabbedDesigner';
import SEO from "~components/seo/SEO";

export const TemplatePage = () => {
  const { t } = useTranslation();

  return (
    <div className="container my-5">
      <SEO title={"Templates - Likhet"} description={`${t('description_document_designer')}`} />
      <div className="d-flex justify-content-center flex-column align-items-center mb-4">
        <h1 className="fw-bold text-primary-color text-center ">
          {t('title_document_designer_one')}  <span className="text-secondary-color">{t('title_document_designer_two')}</span>
        </h1>
        <p className="text-center col col-lg-8 col-md-9 col-sm-12  mt-2">
          {t('description_document_designer')}
        </p>
      </div>

      <TabbedDesigner />
    </div>
  )
}

