import { useState } from 'react';

import Dropdown from 'react-bootstrap/Dropdown';
import i18n from '~services/i18n.service';

import usFlat from '~assets/images/flats/usFlag.svg';
import khmer from '~assets/images/flats/khmerFlag.svg';

function ChangeLanguages() {
  const [language, setLanguage] = useState<string>('km');

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setLanguage(lang);
  };

  return (
    <Dropdown>
      <Dropdown.Toggle variant="light" className={"custom-style px-2 rounded-2 border"} >
        <img src={language === 'en' ? usFlat : khmer} width="40" alt={language === 'en' ? 'us-flat' : 'khmer-flat'} />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleChangeLanguage('km')}>
          <img src={khmer} width="40" alt="khmer-flat" />
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleChangeLanguage('en')}>
          <img src={usFlat} width="40" alt="us-flat" />
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ChangeLanguages;
