export const baseURL = () => {
  if (process.env.NODE_ENV === 'development') {
    return process.env.REACT_APP_API_SERVICE_URL_DEV;
  } else {
    return process.env.REACT_APP_API_PRO;
  }
};

export const ROOT_ROUTE = "/";
export const SOMETHING_WENT_WRONG= "/something-went-wrong";
export const TEMPLATE_PAGE= "/templates";
export const TEMPLATE_UPLOAD = "/template-upload";
export const TEMPLATE_BUILD = "/template-build";
export const TEMPLATE_SEARCH_URL= "/templates/search?templateName"; 
export const TEMPLATE_DETAIL ="/template-detail";
export const TEMPLATE_STATISTICS_URL = "/homepage/ddkh-statistics";
export const MAPPER = "/mappers";
export const TEMPLATE_UPLOAD_FILE_URL = "/files/upload";
export const FIND_TEMPLATE_BY_ID = "/templates";
export const ADD_NEW_TEMPLATE = "/templates";
export const FIND_FILE_URL = "/files";
export const GIT_BOOK = "https://samroths-organization.gitbook.io/ddkh-guide";
export const PRIVACY_POLICY_PAGE = "/privacy";
export const TERMS_PAGE = "/terms";
export const SAVE_DRAFT_TEMPLATE = (templateId: string | undefined) => `/mappers/${templateId}/save-history`;