import ReactDOM from "react-dom/client";

import "./style/App.scss";

import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';

import "./services/i18n.service";
import "~utils/PdfConfig";
import "~utils/CustomizeFabric";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
if (!root) {
  throw new Error("Couldn't find root element");
}
root.render(<App />);
