export const sampleTags = [
  {
    value: 'username',
    label: 'username',
  },
  {
    value: 'workspace',
    label: 'workspace',
  },
  {
    value: 'age',
    label: 'age',
  },
]