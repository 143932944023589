import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import { useAuth } from "react-oidc-context";

import Navbar from "~components/navbar/Navbar";

const TemplateLayout = () => {
  const auth = useAuth();

  useEffect(() => {
    if (!auth.isLoading && !auth.isAuthenticated) {
      auth.signinRedirect();
    }
  }, [auth]);

  return (
    <div className="flex min-h-screen flex-col position-relative">
      <Navbar />
      <main className="flex-grow">
        <Outlet />
      </main>
    </div>
  );
};

export default TemplateLayout;
