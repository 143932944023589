import React, { useEffect, useRef, useState } from 'react';
import { Button, Layout, message, Modal } from 'antd';
import { BiText } from 'react-icons/bi';
import { MdDateRange } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import {useNavigate} from "react-router-dom";

import sample from '~assets/samplePdf/examplePdf.pdf';
import PdfViewerUrlFile from '~components/pdf-viewer/PdfViewer';
import SelectTags from '~components/select-tag/SelectTagDropDown';
import { sampleTags } from '~constants/MockTags';
import {TEMPLATE_PAGE} from "~constants/Routes";

const BuildTemplate: React.FC = () => {
  const { Content, Sider } = Layout;
  const { t } = useTranslation();

  const [loadingFilePdf, setLoadingFilePdf] = useState<boolean>(true);
  const currentRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvas, setCanvas] = useState<fabric.Canvas | null>(null);
  const [tags, setTags] = useState<string>('');
  const navigator = useNavigate();

  const scrollToPage = (index: number) => {
    if (currentRef.current) {
      const pageElement = currentRef.current.querySelector(`#page_${index}`);
      if (pageElement) {
        pageElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  const handleDragStart = (event: React.DragEvent<HTMLDivElement>, id: string) => {
    event.dataTransfer.setData('text', id);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const id = event.dataTransfer.getData('text');

    const dropX = event.clientX - event.currentTarget.getBoundingClientRect().left;
    const dropY = event.clientY - event.currentTarget.getBoundingClientRect().top;

    if (canvas && tags) {
      let newObject: fabric.Object | null = null;
      if (id === 'text-box') {
        newObject = new fabric.Textbox(t('text_box'), {
          left: dropX,
          top: dropY,
          fill: 'blue',
          width: 220,
          height: 100,
          selectable: true,
          hasControls: true,
          textAlign: 'center',
          fontSize: 20,
          cornerSize: 10,
          fontStyle: 'normal', 
          backgroundColor: 'white',
        });
      } else if (id === 'date') {
        newObject = new fabric.Textbox(t('date'), {
          left: dropX,
          top: dropY,
          fill: 'blue',
          width: 300,
          height: 50,
          selectable: true,
          hasControls: true,
          textAlign: 'center',
          fontSize: 20,
          cornerSize: 10,
          fontStyle: 'normal',
          backgroundColor: 'white'
        });
      } else {
        message.error('Sorry! Cannot drop this!');
      }
      if (newObject) {
        (newObject as any).set('tags', tags);
        newObject.on('moving', () => {
          canvas.renderAll();
        });
        canvas.add(newObject);
      }
    } else {
      Modal.warning({
        title: t('functionality_warning'),
        okText: t('understand'),
        content: t('select_tag_warning'),
      });
    }
  };

  useEffect(() => {
    if (loadingFilePdf) {
      message.loading('loading file...');
    }
  }, [loadingFilePdf]);

  useEffect(() => {
    const canvasElement = currentRef.current;
    if (canvasElement) {
      const newCanvas = new fabric.Canvas(canvasRef.current, {
        width: canvasElement?.offsetWidth || 0,
        height: canvasElement?.offsetHeight || 0,
      });

      setCanvas(newCanvas);

      return () => {
        newCanvas.dispose();
      };
    }
  }, []);

  const handleSelectionChange = (value: string) => {
    setTags(value);
  }

  const saveDraft = () => {
    if (!canvas) {
      message.warning("No edit changed!");
      return;
    }

    const json = JSON.stringify(canvas.toJSON());
    const canvasObjects = JSON.parse(json).objects;
    if (!canvasObjects || canvasObjects.length === 0) {
      message.warning("No objects changed!");
      return;
    } else if (!tags) {
      message.warning("No tags selected!");
      return;
    }
    localStorage.setItem('canvasDraft', json);
    message.success("Draft saved successfully!");
  };

  const cancelEditing = () => {
    if (!canvas) {
      localStorage.clear();
      navigator(TEMPLATE_PAGE);
      return;
    } else {
      Modal.warning({
        title: t('cancel_editing_message'),
        content: t('cancel_editing_description'),
        okText: t('understand'),
        onOk() {
          localStorage.clear();
          navigator(TEMPLATE_PAGE);
        }
      });
    }
  }

  return (
    <Layout className='container-drag-and-drop layout-style'>
      <Sider width={'20%'} breakpoint="md" collapsedWidth="0" className='sider-style'>
        <div className='demo-logo-vertical' />
        <div className='pdf-preview'>
          <PdfViewerUrlFile messageLoad='pagination loaded' setLoading={setLoadingFilePdf} urlFile={sample} scrollToPage={scrollToPage} />
        </div>
      </Sider>
      <Layout className='overflow-scroll'>
        <Content className='content-style' id='content'>
          <div ref={currentRef} className='pdf-viewer mh-100 position-relative overflow-x-hidden'>
            <PdfViewerUrlFile messageLoad='File loaded' setLoading={setLoadingFilePdf} urlFile={sample} scrollToPage={scrollToPage} />
            <div
              className='position-absolute top-0 left-0 z-3'
              onDragOver={(event) => event.preventDefault()}
              onDrop={handleDrop}
            >
              <canvas ref={canvasRef} width={'100%'} height={'100%'} id='drop-field'></canvas>
            </div>
          </div>
        </Content>
      </Layout>
      <Sider width={'20%'} breakpoint="sm" collapsedWidth="0" className='sider-style'>
        <h2 className='fw-bold text-secondary-color'>{t('info')}</h2>
        <p className='fs-3'>{t('document_title')}</p>
        <p className='fs-5 text-gray mt-n1'>{t('description_doc')}</p>
        <p className='fs-5 text-gray'>{t('select_tag')}</p>
        <SelectTags size="large" tagsJson={sampleTags} placeHolder={t('select_tag')} key={'tags'} setSelected={handleSelectionChange} />
        <p className='fs-5 mt-4'>{t('tools')}</p>
        <div className={'d-grid gap-4'}>
          <div className='d-flex flex-wrap gap-2'>
            <div id={'tools'} draggable={true} onDragStart={(e) => handleDragStart(e, 'text-box')} className='p-3 box-hover text-center shadow-md bg-white w-fit rounded-3 d-flex gap-1 flex-column justify-content-center align-items-center'>
              <BiText size={30} />
              {t('text_box')}
            </div>
            <div id={'tools'} draggable={true} onDragStart={(e) => handleDragStart(e, 'date')} className='p-3 text-center box-hover shadow-md w-fit bg-white rounded-3 d-flex gap-1 flex-column justify-content-center align-items-center'>
              <MdDateRange size={30} />
              {t('date')}
            </div>
          </div>
          <div className={'d-flex flex-column gap-2'}>
            <Button onClick={saveDraft} className='fw-medium rounded-5 w-100' size='large'>{t('save_draft')}</Button>
            <Button onClick={cancelEditing} className='fw-medium rounded-5 w-100 bg-danger text-white' size='large' danger>{t('cancel_editing')}</Button>
          </div>
        </div>
      </Sider>
    </Layout>
  );
};

export default BuildTemplate;
