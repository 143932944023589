import { Form, Select } from "antd";
import { stringToBoolean } from "~pages/templates/template-detail/TemplateDetailPage";

const SelectDropdown = ({ pageKey, item, onChange }: { onChange: any, pageKey: any, item: any }) => {
  const handleChange = (value: string) => {
    onChange(pageKey, item.label, value);
    const nestedItems = item.item?.find((item: any) => item.value === value);
    if (item.headerOf) {
      if (nestedItems.nested) {
        onChange(pageKey, item.headerOf, nestedItems.nested, true);
      }
    }
  };

  return (
    <Form.Item
      initialValue={item.value || item.display_section}
      name={item.label}
      rules={[
        { required: stringToBoolean(item.required), message: item.display_section + " is required" },
      ]}
    >
      <Select
        defaultValue={item.value || item.display_section}
        title={item.label}
        onSelect={handleChange}
        placeholder={item.value || item.display_section}
        size={'large'}
        status={item.value ?? 'error'}
        value={item.value ?? ''}
        id={`${pageKey}-${item.id}`}
        options={item.item}
      />
    </Form.Item>
  );
};

export default SelectDropdown;