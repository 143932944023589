import {Link} from "react-router-dom";

import { TbExternalLink } from "react-icons/tb";
import SEO from "~components/seo/SEO";

export const PrivacyPolicyPage = () =>{
  return(
    <div className={"d-flex justify-content-center m-auto privacy-policy"}>
      <SEO title={"Privacy - Likhet"} description={"Write Government Letters Efficiently through form!"}/>
      <div>
        <h1 className={"py-4 title-privacy-policy"}>Privacy Policy</h1>
        <p className={"py-2"}>This is the Government Likhet website</p>
        <p className={"py-2"}>If you are only browsing this website, we do not capture data that allows us to identify
          you individually. We
          may use various technologies, including but not limited to cookies, to improve and customize your experience
          on this website and understand how you are using the website.</p>
        <p className={"py-2"}>The technologies we use do not capture any personally identifiable data about you but
          capture your browsing
          pattern on this website and other websites after you browse here. This analysis and information will be used
          by us to improve and customize your experience on this website. They may be shared with our selected group
          of
          partners to improve and customize your experience on their websites.</p>
        <p className={"py-2"}>You can choose to accept or decline cookies. Most web browsers automatically accept
          cookies, but you can
          usually modify your browser setting to decline cookies if you prefer. This may prevent you from taking full
          advantage of the website.</p>
        <div className={"py-2"}>
          <span>If you provide us with personally identifiable data:</span>
          <ol>
            <li className={"pb-4 ps-3"}>We may share necessary data with other Government agencies to serve you most
              efficiently and effectively
              unless such sharing is prohibited by law.
            </li>
            <li className={"ps-3"}>We will NOT share your Personal Data with non-Government entities except where such
              entities have been
              authorized to carry out specific Government services.
            </li>
          </ol>
        </div>
        <p className={"py-2"}>We may also display the data you supplied to other Government Agencies or us. This will
          speed up the
          transaction and save you the trouble of repeating previous submissions. Should the data be out-of-date,
          please
          supply us with the latest data.</p>
        <p className={"py-2"}>To safeguard your Personal Data, all electronic storage and transmission of Personal
          Data is secured with
          appropriate security technologies.</p>
        <p className={"py-2"}>Please contact <Link to={"mailto:info@form.gov.kh"} className={"url-contact-dgc"}>info@likhet.gov.kh<TbExternalLink/>
        </Link> if you have any enquires or feedback and/or need more information on or
          access to data that you have provided to us in the past.
        </p>
      </div>
    </div>
  )
}