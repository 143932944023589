import {useState} from "react";
import { useTranslation } from "react-i18next";

interface ClipboardCopyProps {
  copyText: string
}

const ClipboardCopy = ({ copyText } : ClipboardCopyProps) =>{
  const [isCopied, setIsCopied] = useState(false);

  const { t } = useTranslation();
  async function copyTextToClipboard( text: string ) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return(
    <main className={"input-group my-3 "}>
      <input
        className="form-control clipboard"
        value={copyText}
        readOnly
      />
      <button
        type={"button"}
        className="input-group-text py-2 px-4 text-white border-2 "
        style={{backgroundColor: "#1C4076", borderColor: "#1C4076"}}
        onClick={handleCopyClick}
      >
        { isCopied ? t('copied_json_url_file') : t('copy_json_url_file') }
      </button>
    </main>
  );
}

export default ClipboardCopy;