import { lazy, ReactNode, Suspense, useEffect } from "react";
import { AppLayout } from "~layouts/AppLayout";
import {
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import {
  TEMPLATE_PAGE,
  ROOT_ROUTE,
  SOMETHING_WENT_WRONG,
  TEMPLATE_UPLOAD,
  TEMPLATE_DETAIL,
  TEMPLATE_BUILD,
  FIND_FILE_URL,
  PRIVACY_POLICY_PAGE,
  TERMS_PAGE,
} from "~constants/Routes";
import { lazyRetry } from "~utils/lazyRetry";
import { setupResponseInterceptor } from "~utils/hook";
import Templates from "~pages/templates/index";
import TemplateLayout from "~layouts/TemplateLayout";
import TemplateUpload from "~pages/designer-pages/template-creation-page/TemplateUpload";
import BuildTemplate from "~pages/designer-pages/build-template/BuildTemplate";
import TemplateDetailPage from "~pages/templates/template-detail";
import ViewFileGenerated from "~pages/templates/view-file-generated/ViewFileGenerated";
import {PrivacyPolicyPage} from "~pages/privacy/PrivacyPolicyPage";
import {AppLayoutGlobal} from "~layouts/AppLayoutGlobal";
import TermsPage from "~pages/terms";
import Loading from "~components/loading-page/Loading";

const LandingPage = lazy(() => lazyRetry(() => import("src/pages/landing-page")));
const UnderConstructionPage = lazy(() =>
  lazyRetry(() => import("src/pages/under-construction-page"))
);
const SomethingWentWrongPage = lazy(() =>
  lazyRetry(() => import("src/pages/something-went-wrong-page"))
);

const WithSuspense = ({ children }: { children: ReactNode }) => (
  <Suspense fallback={<Loading addedClassName={'position-absolute top-0 w-100 left-0 sectionHeight'} />}>
    {children}
  </Suspense>
);

export const AppRouter = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setupResponseInterceptor(navigate);
  }, [navigate]);

  return (
    <WithSuspense>
      <Routes>

        <Route element={<AppLayout />}>
          <Route
            path={SOMETHING_WENT_WRONG}
            element={<SomethingWentWrongPage />}
          />
          <Route path={ROOT_ROUTE} element={<LandingPage />} />
        </Route>

        <Route element={<AppLayoutGlobal/>}>
          <Route path={PRIVACY_POLICY_PAGE} element={<PrivacyPolicyPage/>}/>
          <Route path={TERMS_PAGE} element={<TermsPage/>} />
        </Route>

        <Route element={<TemplateLayout />}>
          <Route path={TEMPLATE_PAGE} element={<Templates />} />
          <Route path={TEMPLATE_UPLOAD} element={<TemplateUpload />} />
          <Route path={TEMPLATE_DETAIL + '/:id'} element={<TemplateDetailPage />} />
          <Route path={TEMPLATE_BUILD} element={<BuildTemplate />} />
          <Route path={FIND_FILE_URL + '/:filename'} element={<ViewFileGenerated />} />
        </Route>

        <Route path="*" element={<UnderConstructionPage />} />
      </Routes>
    </WithSuspense>
  );
};