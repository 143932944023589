import { Helmet ,HelmetProvider } from 'react-helmet-async';

import { SEOProps } from "~typings/types";

const SEO = ({title , description} : SEOProps) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title}</title>
        <meta name='description' content={description} />
        </Helmet>
    </HelmetProvider>
  )
}

export default SEO;