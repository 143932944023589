import { FormEvent, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Modal, Radio, message, Input, Form, Collapse, DatePicker, Flex, Checkbox } from "antd";
import { t } from "i18next";
import { Modal as ModalBoot } from 'react-bootstrap';
import JsonView from "@uiw/react-json-view";
import { VscVmConnect } from "react-icons/vsc";
import TextArea from "antd/es/input/TextArea";
import { useAuth } from "react-oidc-context";
import { BiSave } from "react-icons/bi";
import dayjs from "dayjs";
import { produce } from "immer";

import { useAxios } from "~utils/hook";
import { FIND_TEMPLATE_BY_ID, MAPPER, SAVE_DRAFT_TEMPLATE, baseURL } from "~constants/Routes";
import ClipboardCopy from "~components/functional-designer/ClipboardCopy";
import { DataType, TemplateInfoProps } from "~typings/types";
import DynamicForm from "~components/dynamic-form/DynamicForm";
import RadioButtons from "~components/radio/Radios";
import SelectDropdown from "~components/select-dropdown/SelectDropdown";
import SEO from "~components/seo/SEO";
import Loading from "~components/loading-page/Loading";

export const TemplateDetailPage = () => {
  const { id } = useParams();


  const { Panel } = Collapse;
  const abortController = new AbortController();
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [option, setOption] = useState<string>('pdf');
  const [sampleJson, setSampleJson] = useState<{ [key: string]: any }>({});
  const [show, setShow] = useState(false);
  const [templateInfo, setTemplateInfo] = useState<TemplateInfoProps>({
    name: '',
    description: ''
  })
  const [loadingPage, setLoadingPage] = useState<boolean>(true);
  const [form] = Form.useForm();
  const axiosClient = useAxios();
  const auth = useAuth();


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const enterLoading = ({ index, isLoading }: { index: number, isLoading: boolean }): any => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = isLoading;
      return newLoadings;
    });
  }
  const alertUser = (e: BeforeUnloadEvent) => {
    e.preventDefault();
    e.returnValue = '';
  };
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);
  const onSelectChange = (value: string) => {
    setOption(value);
  };

  const handleSaveDraft = async () => {
    try {
      await axiosClient.current?.post(SAVE_DRAFT_TEMPLATE(id), sampleJson)
          .then((response) => message.success("Draft updated successfully"))
          .catch((e: any) => message.error(e.message));
    } catch (e: any) { 
      message.error(e.message);
    }
  }

  const handleGenerate = (e: FormEvent<HTMLFormElement>) => {
    enterLoading({ index: 1, isLoading: true });
  
    if (Object.keys(sampleJson).length > 0) {
      Modal.confirm({
        title: t("confirm_generate_file") + option,
        centered: true,
        content: t("confirm_generate_file_description"),
        okText: t('yes'),
        cancelText: t('cancel'),
        onCancel: () => {
          abortController.abort();
        },
        onOk: async () => {
          try {
            const response = await axiosClient.current?.post(`${MAPPER}/${id}?option=${option}`, sampleJson, {
              responseType: 'blob',
              signal: abortController.signal,
          });
  
            let contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
            let downloadFileName = `${templateInfo.name}.docx`;
  
            if (option === 'pdf') {
              contentType = 'application/pdf';
              downloadFileName = `${templateInfo.name}.pdf`;
            }
  
            const blob = new Blob([response?.data], { type: contentType });
            const url = URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = downloadFileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);

            enterLoading({ index: 1, isLoading: false });
          } catch (error) {
            Modal.error({
              title: t('failed_title'),
              centered: true,
              content: t('failed_content'),
              onOk: () => enterLoading({ index: 1, isLoading: false }),
            });
          }
        },
      });
    } else {
      message.error(t('template_not_found'));
    }
  };

  useEffect(() => {
    setLoadingPage(true);
    const fetchData = async () => {
      if (!auth.user?.access_token || !axiosClient.current || !axiosClient.current) return;


      try {
        const res = await axiosClient.current.get(`${FIND_TEMPLATE_BY_ID}/${id}`);
        setLoadingPage(false);
        if (!res || !res.data) {
          message.error('Failed to fetch data');
          return;
        }

        setSampleJson(res.data.templateBody.templateJson);

        const updatedInfo = {
          ...templateInfo,
          name: res.data.templateName,
          description: res.data.description
        };
        
        setTemplateInfo(updatedInfo);
      } catch (err: any) {
        console.error(err.message);
      }
    };
    
    fetchData();
  }, [id, auth.user?.access_token]);

  const updateValue = (key: string, subKey: string, newValue: any, nested?: boolean) => {
    setSampleJson(produce((draft: DataType) => {
      if (!draft[key]) {
        message.error(`The key "${key}" does not exist.`);
        return;
      }
      if (!draft[key][subKey]) {
        message.error(`The subKey "${subKey}" does not exist under "${key}".`);
        return;
      }
      if (!nested) {
        draft[key][subKey].value = newValue;
      } else {
        draft[key][subKey].item = newValue;
        draft[key][subKey].value = null;
      }
    }));
  };

  const updateRadio = ({ key, subKey, nestedKey, newValue }: { key: string, nestedKey: string, subKey: string, newValue: any }) => {
    setSampleJson(produce((draft: DataType) => {
      if (!draft[key]) {
        message.error(`The key "${key}" does not exist.`);
        return;
      }
      if (!draft[key][subKey]) {
        message.error(`The subKey "${subKey}" does not exist under "${key}".`);
        return;
      }
      if (!draft[key][subKey].nested_item[nestedKey]) {
        message.error(`The nestedKey "${subKey}" does not exist under "${key}".`);
        return;
      }
      Object.keys(draft[key][subKey].nested_item).forEach(prev => {
        draft[key][subKey].nested_item[prev].value = 'false';
      });

      draft[key][subKey].nested_item[nestedKey].value = newValue.toString();
    }));
  }

  const generateInputField = (pageKey: any, item: any) => {
    switch (item.type) {
      case 'checkbox': 
        return (
          <Checkbox className="fs-6 my-1" checked={stringToBoolean(item.value)} onChange={(e: any) => updateValue(pageKey, item.label.toString(), e.target.checked.toString())} >
            {item.display_section}
          </Checkbox>
        )
      case 'table':
        return <DynamicForm pageKey={pageKey} items={item} onChange={updateValue} />
      case 'select':
        return <SelectDropdown key={item.label} onChange={updateValue} item={item} pageKey={pageKey} />;
      case 'number':
        return (
          <Form.Item
            name={item.id}
            rules={[
              { required: stringToBoolean(item.required) && !item.value, message: item.display_section + " is required" },
            ]}
          >
            <Input
              allowClear
              className={'w-100'}
              type={'number'}
              id={`${pageKey}-${item.id}`}
              placeholder={item.value || item.display_section}
              size="large"
              style={{ borderColor: item.required ? undefined : 'red' }}
              name={item.label}
              defaultValue={item.value}
              min={1}
              onChange={(e) => updateValue(pageKey, item.label.toString(), e.target.value)}
            />
          </Form.Item>
        )
      case 'age':
        return (
          <Form.Item
            name={item.id}
            rules={[
              { required: stringToBoolean(item.required) && !item.value, message: item.label + " is required" },
              {
                validator: (_, value) => {
                  if (value && (value < 10 || value > 120)) {
                    return Promise.reject(new Error(`${item.id} must be between 10 and 120.`));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              allowClear
              defaultValue={item.value}
              type="number"
              id={item.id}
              size="large"
              style={{ borderColor: item.required ? undefined : 'red' }}
              placeholder={item.value || item.display_section}
              name={item.id}
              onChange={(e) => updateValue(pageKey, item.label.toString(), e.target.value)}
            />
          </Form.Item>
        );
      case 'radio':
        return (
          <RadioButtons item={item} keyPage={pageKey} subKey={item.label} updateRadio={updateRadio} />
        )
      case 'textAndRadio':
        return (
          <div>
            <Form.Item
              name={item.id}
              rules={[{ required: stringToBoolean(item.required) && !item.value, message: item.display_section + " is required" }]}
            >
              <Input
                allowClear
                className="mb-2"
                type={'text'}
                id={`${pageKey}-${item.id}`}
                placeholder={item.value || item.display_section}
                size="large"
                style={{ borderColor: item.required ? undefined : 'red' }}
                name={item.id.toString()}
                defaultValue={item.value}
                onChange={(e) => updateValue(pageKey, item.label.toString(), e.target.value)}
              />
              <RadioButtons item={item} keyPage={pageKey} subKey={item.label} updateRadio={updateRadio} />
            </Form.Item>
          </div>
        );
      case 'date':
        return (
          <Form.Item
            name={item.id}
            rules={[{ required: stringToBoolean(item.required) && !item.value, message: item.display_section + " is required" }]}
          >
            <DatePicker
              id={`${pageKey}-${item.id}`}
              placeholder={item.display_section}
              size="large"
              className="w-100"
              style={{ borderColor: item.required ? undefined : 'red' }}
              name={item.id.toString()}
              defaultValue={item.value ? dayjs(item.value, 'DD/MM/YYYY') : undefined}
              format={'DD/MM/YYYY'}
              picker="date"
              onChange={(date, dateString) => updateValue(pageKey, item.label.toString(), dateString)}
            />
          </Form.Item>
        )
      case 'month':
        return (
          <Form.Item
            name={item.id}
            rules={[{ required: stringToBoolean(item.required) && !item.value, message: item.display_section + " is required" }]}
          >
            <DatePicker
              id={`${pageKey}-${item.id}`}
              placeholder={item.display_section}
              size="large"
              className="w-100"
              style={{ borderColor: item.required ? undefined : 'red' }}
              name={item.id.toString()}
              defaultValue={item.value ? dayjs(item.value, 'MM/YYYY') : undefined}
              format={'MM/YYYY'}
              picker="month"
              onChange={(date, dateString) => updateValue(pageKey, item.label.toString(), dateString)}
            />
          </Form.Item>
        );
      case 'monthOnly':
        return (
          <Form.Item
            name={item.id}
            rules={[
              { required: stringToBoolean(item.required) && !item.value, message: item.display_section + " is required" },
              {
                validator(_, value) {
                  if (!value || (value >= 1 && value <= 12)) {
                    return Promise.resolve(new Error(`${item.id} must be between 1 and 12.`));
                  }
                  return Promise.reject(new Error('Value must be between 1 and 12!'));
                },
              }
            ]}
          >
            <Input
              allowClear
              className={'w-100'}
              type={'number'}
              size="large"
              id={`${pageKey}-${item.id}`}
              placeholder={item.value || item.display_section}
              style={{ borderColor: item.required ? undefined : 'red' }}
              name={item.label}
              defaultValue={item.value}
              min={1}
              max={12}
              onChange={(e) => updateValue(pageKey, item.label.toString(), e.target.value)}
            />
          </Form.Item>
        )
      case 'textarea':
        return (
          <Form.Item
            initialValue={item.value}
            name={item.id}
            rules={[{ required: stringToBoolean(item.required) && !item.value, message: item.display_section + " is required" }]}
          >
            <TextArea
              allowClear
              defaultValue={item.value}
              id={`${pageKey}-${item.id}`}
              placeholder={item.value || item.display_section}
              size="large"
              style={{ borderColor: item.required ? undefined : 'red' }}
              name={item.id}
              autoSize={{ minRows: 5, maxRows: 20 }}
              onChange={(e) => updateValue(pageKey, item.label.toString(), e.target.value)}
            />
          </Form.Item>
        );
      case 'year':
        return (
          <Form.Item
            name={item.id}
            rules={[
              { required: stringToBoolean(item.required) && !item.value, message: item.display_section + " is required" }
            ]}
          >
            <DatePicker
              id={`${pageKey}-${item.id}`}
              placeholder={item.display_section}
              size="large"
              className="w-100"
              style={{ borderColor: item.required ? undefined : 'red' }}
              name={item.id.toString()}
              defaultValue={item.value ? dayjs(item.value, 'YYYY') : undefined}
              format={'YYYY'}
              picker="year"
              onChange={(date, dateString) => updateValue(pageKey, item.label.toString(), dateString)}
            />
          </Form.Item>
        )
      default:
        return (
          <Form.Item
            name={item.id}
            rules={[{ required: stringToBoolean(item.required) && !item.value, message: item.display_section + " is required" }]}
          >
            <Input
              allowClear
              type={item.type}
              id={`${pageKey}-${item.id}`}
              placeholder={item.display_section}
              size="large"
              style={{ borderColor: item.required ? undefined : 'red' }}
              name={item.id.toString()}
              defaultValue={item.value}
              onChange={(e) => updateValue(pageKey, item.label.toString(), e.target.value)}
            />
          </Form.Item>
        );
    }
  };
  return (
    <div className="container my-5">
      <SEO title={`${templateInfo.name || t('template_not_found')} - Likhet`} description={`${templateInfo.description}` } />
      <div className="mx-lg-5 mx-sm-0">
        <div className="text-center py-lg-5">
          <h2 className="text-primary-color fw-bold mb-2">{templateInfo.name || t('template_not_found')}</h2>
          <p className="fs-6 opacity-75">{templateInfo.description}</p>
        </div>
        <div className="justify-content-end d-flex">
            <Button
              type="primary"
              onClick={handleShow}
              shape="default"
              size="large"
              className="fit-width mb-2"
              icon={<VscVmConnect />}
            >
              {t('connect_with_api')}
            </Button>
          <ModalBoot
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
          >
            <ModalBoot.Header closeButton>
              <ModalBoot.Title>{t("title_sample_json_file")}</ModalBoot.Title>
            </ModalBoot.Header>
            <ModalBoot.Body>
              <div>
                <ClipboardCopy copyText={`${baseURL()}${FIND_TEMPLATE_BY_ID}/template-json-data/${id}`} />
              </div>
              <div className="overflow-y-auto" style={{ height: "500px" }}>
                <JsonView className="fs-6" value={sampleJson} displayDataTypes={false} />
              </div>
            </ModalBoot.Body>
          </ModalBoot>
        </div>
        <Form form={form} onFinish={handleGenerate}>
          {loadingPage && <Loading/>}
          {
            !loadingPage &&(
              (!sampleJson || Object.keys(sampleJson).length === 0) ? (
                <p className={'text-center fs-5 fw-normal'}>{t('form_not_provided')}</p>
              ) : (
                <Collapse defaultActiveKey={['1']} accordion>
                  {Object.keys(sampleJson)
                    .map((pageKey) => ({ pageKey, ...sampleJson[pageKey] }))
                    .sort((a, b) => a.position - b.position)
                    .map(({ pageKey, ...panel }) => (
                      <Panel header={`${panel.position}. ${panel.display_section || 'Untitled'}`} key={panel.position} className={'text-capitalize bg-white rounded shadow-sm fs-5 fw-medium'}>
                        {panel.description && <p className={'fw-semibold text-primary-color'}>{panel.description}</p>}
                        <div className={`row`}>
                          {Object.keys(panel)
                            .filter(key => key !== 'position')
                            .map((fieldKey) => panel[fieldKey])
                            .filter(field => field && typeof field === 'object')
                            .sort((a, b) => a.id - b.id)
                            .map((field) => (
                              <div key={field.id} className={'d-flex flex-column gap-1 fw-normal text-fourth-color'}>
                                <label htmlFor={`${pageKey}-${field.id}`} className={`text-capitalize ${field.type !== 'checkbox' ? 'block' : 'd-none'}`}>
                                  {field.display_section || "Untitled"} {stringToBoolean(field.required) ? <span className="text-danger">*</span> : null}
                                </label>
                                {field && field.type ? generateInputField(pageKey, field) : null}
                              </div>
                            ))}
                        </div>
                      </Panel>
                    ))}
                </Collapse>
              )
            )
          }
          <div className="mt-4 d-flex flex-wrap gap-2 align-items-start justify-content-start">
            <p className={'fs-6'}>{t('select_file_type')}: </p>
            <Radio.Group onChange={(e) => onSelectChange(e.target.value)} value={option}>
              <Radio value={'pdf'}>PDF</Radio>
              <Radio value={'docx'}>WORD - DOCX</Radio>
            </Radio.Group>
          </div>
          <Flex gap={"small"} className="my-2">
            <Button onClick={handleSaveDraft} type="default" shape="default" className="text-primary-color fw-medium border-primary-color" icon={<BiSave />} size={"large"}>
              {t('save_draft')}
            </Button>
            <Button type="primary" htmlType="submit" shape="default" size={"large"}>
              {t('start_generate_json_file')}
            </Button>
          </Flex>
        </Form>
      </div>
    </div>
  )
}

export const stringToBoolean = (value: string | undefined): boolean => {
  return value?.toLowerCase() === 'true';
}