import { useAuth } from 'react-oidc-context';
import { Link } from "react-router-dom";

import ChangeLanguages from './dropdown-change-languages/ChangeLanguages';
import logo from '~/assets/logo/Likhet_Horizontal_Black_Bg.svg';
import AuthButton from './AuthButton';


export default function Navbar() {
  const auth = useAuth();

  return (
    <div className="bg-white border-bottom">
      <nav className={"d-flex sticky-top justify-content-between align-items-center py-2 px-md-4 px-1"}>
        <Link to={auth.isAuthenticated ? '/templates' : '/'} className="no-background images-logo text-black border-0 rounded-3">
          <img src={logo} alt='logo' className="logo-navbar" />
        </Link>
        <div className="d-flex gap-2 justify-content-end align-items-center">
          <ChangeLanguages />
          <AuthButton />
        </div>
      </nav>
    </div>

  )
}
