import { useEffect, useRef, useState } from "react";
import { Document, Page } from "react-pdf";

import { FileProp } from "~typings/types";
import { message } from "antd";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

const PdfViewerUrlFile = ({ urlFile, setLoading, scrollToPage, messageLoad }: FileProp) => {
  const [numPages, setNumPages] = useState<number>(0);
  const [scale, setScale] = useState<number>(1);
  const containerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>();

  const navigate = useNavigate();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTimeout(() => {
      setLoading(false);
      message.success(messageLoad);
    }, 1000);
    setNumPages(numPages);
  }

  useEffect(() => {
    const updateScale = () => {
      if (containerRef.current) {
        const containerWidth = containerRef.current.offsetWidth;
        setWidth(containerWidth);
        const standardPageWidth = 612;
        const newScale = containerWidth / standardPageWidth;
        setScale(newScale);
      }
    };
    updateScale();
    window.addEventListener('resize', updateScale);
    return () => window.removeEventListener('resize', updateScale);
  }, []);

  const handleRoute = (index: number) => {
    scrollToPage(index);
    navigate(`#page_${index}`);
  }

  return (
    <div ref={containerRef} style={{
      width: width
    }} className="z-0 m-auto mh-100">
      <Document
        file={urlFile}
        onLoadSuccess={onDocumentLoadSuccess}
        onError={(error) => console.error('Error loading PDF:', error)}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <div id={`page_${index + 1}`} key={`page_${index + 1}`} onClick={() => handleRoute(index + 1)}>
            <Page scale={scale} pageNumber={index + 1} className={'shadow-sm page-hover'} />
            <p className="text-center mt-3">{t('page')} {index + 1}</p>
          </div>
        ))}
      </Document>
    </div>
  );
};
export default PdfViewerUrlFile;
