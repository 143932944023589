import { Button, DatePicker, Form, Input, message } from "antd";
import { CgRemove } from "react-icons/cg";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import dayjs from "dayjs";
import { t } from "i18next";
import { v4 as uuidv4 } from 'uuid';

import { stringToBoolean } from "~pages/templates/template-detail/TemplateDetailPage";
import { DynamicFormProps, Item, ItemProps } from "~typings/types";

const DynamicForm = (props: DynamicFormProps) => {
  const { pageKey, items, onChange } = props;
  
  const handleAddNewRow = ({ max_row }: {max_row: number }) => {
    const firstRowById = items.value.map((item: { row: Item[] }) => item.row)[items.value.length - 1];

    if (items.value.length >= max_row) {
      message.error(`Sorry We only need ${max_row} rows`);
      return;
    }

    const updatedItems = firstRowById.map((item: Item, index: number) => ({
      ...item,
      id: uuidv4(),
    }));

    const newObject = {
      id: uuidv4(),
      row: updatedItems,
      max_row: max_row,
    };

    const updatedValue = [...items.value, newObject];
    onChange(pageKey, items.label.toString(), updatedValue);
  };

  const handleInputChange = (value: any, outerIndex: number, innerIndex: number) => {
    const updatedValue = JSON.parse(JSON.stringify(items.value));
    updatedValue[outerIndex].row[innerIndex].value = value;
    onChange(pageKey, items.label.toString(), updatedValue);
  }

  const handleRemoveRowById = (itemIdToRemove: number) => {
    const updatedValue = items.value.filter((item: any) => item.id !== itemIdToRemove);
    onChange(pageKey, items.label, updatedValue);
  };
  
  const renderDatePicker = (picker: 'year' | 'month', format: string, item: Item, outerIndex: number, innerIndex: number) => (
    <Form.Item
      name={item.id}
      rules={[{ required: stringToBoolean(item.required) &&!item.value, message: `${item.label} is required` }]}
    >
      <DatePicker
        id={`${pageKey}-${item.id}`}
        placeholder={item.display_section}
        size="large"
        className="w-100"
        style={{ borderColor: item.required? undefined : 'red' }}
        name={item.id.toString()}
        value={item.value? dayjs(item.value, format) : null}
        defaultValue={item.value? dayjs(item.value, format) : undefined}
        format={format}
        picker={picker}
        onChange={(date, dateString) => handleInputChange(dateString, outerIndex, innerIndex)}
      />
    </Form.Item>
  );
  
  const renderInput = (item: Item, outerIndex: number, innerIndex: number) => (
    <Form.Item
      name={item.id}
      rules={[{ required: stringToBoolean(item.required) &&!item.value, message: `${item.label} is required` }]}
    >
      <Input
        allowClear
        type={item.type}
        id={`${pageKey}-${item.id}`}
        placeholder={item.display_section}
        size="large"
        style={{ borderColor: item.required? undefined : 'red' }}
        name={item.id.toString()}
        value={item.value}
        defaultValue={item.value}
        onChange={(e) => handleInputChange(e.target.value, outerIndex, innerIndex)}
      />
    </Form.Item>
  );
  
  const handleRenderType = ({ item, outerIndex, innerIndex }: { item: Item, outerIndex: number, innerIndex: number }) => {
    switch (item.type) {
      case 'year':
        return renderDatePicker('year', 'YYYY', item, outerIndex, innerIndex);
      case 'month':
        return renderDatePicker('month', 'MM/YYYY', item, outerIndex, innerIndex);
      default:
        return renderInput(item, outerIndex, innerIndex);
    }
  };
  
  
  return (
    <section>
      {items.value.length === 0 && <p className={'text-center fs-5 fw-normal fw-medium text-primary-color'}>{t('form_not_provided')}</p>}
      {items.value.map((item: ItemProps, outerIndex: number) => (
        <div key={item.id} className="mb-3 p-3 border rounded">
          <div className={`row ${item.row.length >= 3 ? 'row-cols-lg-3 row-cols-md-2 row-cols-1' : 'row-cols-lg-2 row-cols-1'}`}>
            {item.row.map((rowItem, innerIndex) => (
              <div className="d-flex flex-column mb-2" key={item.id}>
                <label htmlFor={`${pageKey}-${item.id}`} className="font-weight-bold mb-1">{rowItem.display_section}</label>
                {handleRenderType({ item: rowItem, outerIndex: outerIndex, innerIndex: innerIndex })}
              </div>
            ))}
          </div>
          <div className={'d-flex gap-3 mt-4 justify-content-end align-items-center'}>
            {(outerIndex === items.value.length - 1) &&
              <Button
                size='large'
                type="primary"
                className={`background-primary-color fs-6 getstart fit-width rounded-2 d-flex gap-2 justify-content-center align-items-center ${(items.value.length >= item.max_row) ? 'd-none' : ''}`}
                onClick={() => handleAddNewRow({ max_row: item.max_row })}
              >
                <AiOutlineAppstoreAdd /> {t("add_new")}
              </Button>
            }
            {items.value.length > 1 &&
              <Button
                size={'large'}
                className={'rounded-2 d-flex fs-6 fit-width gap-2 justify-content-center align-items-center'}
                type={'dashed'}
                danger
                onClick={() => handleRemoveRowById(item.id)}
              ><CgRemove /> {t('remove')}</Button>
            }
          </div>
        </div>
      ))}
    </section>
  );
};

export default DynamicForm;
