import { useState } from "react";
import { useNavigate } from 'react-router-dom';
import {Button, Input, message, Modal, Spin} from "antd";
import { t } from "i18next";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import TextArea from "antd/es/input/TextArea";
import { LoadingOutlined } from '@ant-design/icons';

import DragAndDropFile from "~components/drag-drop-file/DragDropFile";
import ToolTips from "~components/tool-tip/ToolTip";
import { addNewTemplateProps, handleChangeInputDataProps } from "~typings/types";
import { useAxios } from "~utils/hook";
import { ADD_NEW_TEMPLATE } from "~constants/Routes";
import { ReadJsonFile } from "~utils/ReadJsonFile";
import SEO from "~components/seo/SEO";

const TemplateUpload = () => {
  const history = useNavigate();
  const [fileTemplate, setFileTamplate] = useState<Blob | String>("");
  const [isLoading, setLoading] = useState<boolean>(false);
  const [inputData, setData] = useState<addNewTemplateProps>({
    isDraft: false,
    description: '',
    fileName: '',
    templateName: '',
    templateJson: {},
  });

  const service = useAxios();
  const handleJsonFileUpload = async (files: FileList) => {
    try {
      const jsonData = await ReadJsonFile(files);
      setData((prevState: any) => ({
        ...prevState,
        templateJson: jsonData,
      }));
    } catch (error: any) {
      message.error(t('upload_failed'));
    }
  }

  const handleFileUpload = (files: FileList) => {
    setFileTamplate(files[0])
  };

  const handleChangeInputData = ({ type, event }: handleChangeInputDataProps) => {
    setData((prevState: any) => ({
      ...prevState,
      [type]: event.target.value,
    }));
  }
  
  const setTemplateJsonToNull = () => {
    if (inputData.templateJson) {
      setData((pre: addNewTemplateProps) => ({
        ...pre,
        templateJson: null
      }))
    }
  }

  const handleAddNewTemplate = async () => {
    if (inputData.description && inputData.templateName && fileTemplate && inputData.templateJson) {
      setLoading(true);

      const formData = new FormData();
      formData.append('templateFile', fileTemplate as Blob);
      formData.append('templateName', inputData.templateName);
      formData.append('description', inputData.description);
      formData.append('fileName', inputData.fileName);
      formData.append('templateJson', JSON.stringify(inputData.templateJson));
      formData.append('isDraft', inputData.isDraft.toString())
      
      service.current?.post(`${ADD_NEW_TEMPLATE}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }).then(() => {
        setLoading(false);
        history(-1);
      }).catch(() => {
        setLoading(false);
        Modal.error({
          title: t('failed_title'),
          content: t('upload_failed'),
          centered: true,
        });
      });
    } else {
      Modal.error({
        title: t('failed_title'),
        content: t('feild_fill_message'),
        centered: true
      });
    }
  };

  return (
    <section className="container py-5">
      <SEO title={"Template Upload - Likhet"} description={"Write Government Letters Efficiently through form!"} />
      <div className="justify-content-end d-flex">
        <button onClick={() => history(-1)} className="border-0 bg-transparent text-primary-color">
          <IoChevronBackCircleSharp size={40} />
        </button>
      </div>
      <div className="my-4">
        <h1 className="text-center fw-semibold text-primary-color">{t('upload_template')}</h1>
        <p className="col col-lg-9 col-md-10 col-sm-12 text-center m-auto fst-normal opacity-75 text-fourth-color my-4">{t('upload_description')}<ToolTips color="#E3B800" textContent={t("upload_tips")} /></p>
      </div>
      <div className="container p-lg-5 p-md-3 p-md-1 d-flex flex-column gap-3">
        <label htmlFor="documentTitle">{t('document_title')} <span className="text-danger">*</span></label>
        <Input onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeInputData({ type: 'templateName', event: e })} required id="documentTitle" autoFocus placeholder={t('document_title')} size="large" className="fs-6" />
        <label htmlFor="documentDescription">{t('document_description')} <span className="text-danger">*</span></label>
        <TextArea required onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => handleChangeInputData({ type: 'description', event: e })} id="documentDescription" placeholder={t('document_description')} autoSize={{ minRows: 3, maxRows: 10 }} className="fs-6" size="large" />
        <div className="d-flex flex-column gap-3">
          <DragAndDropFile
            setFileToNull={setTemplateJsonToNull}
            handleErrorMessage={{
              title: t('file_dropped_format_error_title'),
              description: t('file_dropped_json_format_error_description')
            }}
            accpetedFileType={['application/json']} description={t('upload_json_file_description')} onFileDrop={handleJsonFileUpload} />
          <DragAndDropFile
            setFileToNull={setFileTamplate}
            handleErrorMessage={{
              title: t('file_dropped_format_error_title'),
              description: t('file_dropped_format_error_description')
            }}
            accpetedFileType={
              ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']
            }
            description={t('drag_and_drop_description')} onFileDrop={handleFileUpload} />
        </div>
        <Button disabled={isLoading} onClick={handleAddNewTemplate} shape="default" type='primary' size={'large'} className={`getstart w-100 my-4 ${isLoading ? 'bg-white text-black border-1 border-black' : 'background-primay-color text-white'}`}>
          {isLoading &&   <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} />} {t('build_your_template')}
        </Button>
      </div>
    </section>
  )
}
export default TemplateUpload;