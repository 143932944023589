import { Select } from 'antd';

import { SelectProps } from '~typings/types';

const SelectTags = ({ tagsJson, placeHolder, setSelected, size }: SelectProps) => {
  const onChange = (value: string) => {
    if (setSelected) {
      setSelected(value);
    }
  };

  return (
    <Select
      allowClear
      className='w-100 mt-n1'
      size={size}
      placeholder={placeHolder}
      onChange={onChange}
      options={tagsJson}
    />
  );
};

export default SelectTags;
