import { useParams } from "react-router-dom"

import { Layout, message, } from 'antd';
import { useEffect, useState } from "react";
import { useAxios } from "~utils/hook";
import { FIND_FILE_URL } from "~constants/Routes";
import { Document, Page } from "react-pdf";
import { t } from "i18next";


export default function ViewFileGenerated() {
  const { filename } = useParams();
  const [displaUrl, setDisplayUrl] = useState<string>();
  const service = useAxios();
  const [numPages, setNumPages] = useState<number>(0);

  useEffect(() => {
    const fetchPdf = async () => {
      await service.current?.get(`${FIND_FILE_URL}/${filename}`)
        .then((res) => setDisplayUrl(res.data.fileLocation))
        .catch((error) => {
          message.error(error.message)
        })
    }

    fetchPdf();

  }, [filename]);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setTimeout(() => {
      message.success('Success!');
    }, 1000);
    setNumPages(numPages);
  }

  return (
    <Layout className="p-4">
      <div style={{
        width: 'auto'
      }} className="z-0 m-auto mh-100">
        <Document
          file={displaUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          onError={(error) => console.error('Error loading PDF:', error)}
        >
          {Array.from(new Array(numPages), (el, index) => (
            <div id={`page_${index + 1}`} key={`page_${index + 1}`}>
              <Page scale={1.5} pageNumber={index + 1} className={'shadow-sm page-hover'} />
              <p className="text-center mt-3">{t('page')} {index + 1}</p>
            </div>
          ))}
        </Document>
      </div>
    </Layout>
  )
}
