import { fabric } from 'fabric'
import {TextboxOptions} from "~typings/types";

const deleteIcon = new Image();
// set deleteIcon
deleteIcon.src = 'https://cdn-icons-png.flaticon.com/512/1828/1828843.png'


// confict delete object.
const deleteObject = (_: MouseEvent, transform: fabric.Transform): boolean => {
  const { target } = transform
  const { canvas } = target
  canvas?.remove(target)
  canvas?.requestRenderAll()
  return true
}

const renderIcon = (
  ctx: CanvasRenderingContext2D,
  left: number,
  top: number,
  styleOverride: any,
  fabricObject: fabric.Object,
) => {
  const size = 18
  ctx.save()
  ctx.translate(left, top)
  ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle ?? 0))
  ctx.drawImage(deleteIcon, -size / 2, -size / 2, size, size)
  ctx.restore()
}

fabric.Object.prototype.controls.deleteControl = new fabric.Control({
  x: 0.5,
  y: -0.5,
  offsetY: -20,
  offsetX: 20,
  cursorStyle: 'pointer',
  mouseUpHandler: deleteObject,
  render: renderIcon,
})
fabric.Object.prototype.set({
  transparentCorners: false,
  borderColor: '#2e41cec7',
  cornerColor: '#fff',
  cornerStyle: 'circle',
  cornerStrokeColor: '#2e41cec7',
  cornerSize: 16,
})
fabric.Textbox = fabric.util.createClass(fabric.Textbox, {
  type: "textbox",
  strokeWidth: 5,
  splitByGrapheme: true,
  tags: 'hey',
  rx: 0,
  ry: 0,

  initialize: function (text: string, options?: TextboxOptions) {
    this.callSuper('initialize', text, options);
    this.set('tags', options ? options.tags : '');
  },

  toObject: function () {
    return fabric.util.object.extend(this.callSuper("toObject"), {
      tags: this.get('tags'),
      backgroundColor: this.get("backgroundColor"),
      padding: this.get("padding"),
      splitByGrapheme: this.get("splitByGrapheme"),
      rx: this.get("rx"),
      ry: this.get("ry")
    });
  },

  _renderBackground: function (ctx: any) {
    if (!this.backgroundColor) {
      return;
    }
    var dim = this._getNonTransformedDimensions();
    ctx.fillStyle = this.backgroundColor;
    ctx.fillRect(
        -dim.x / 2 - this.padding,
        -dim.y / 2 - this.padding,
        dim.x + this.padding * 2,
        dim.y + this.padding * 2
    );
    // If there is a background color, no other shadows should be casted
    this._removeShadow(ctx);
  }
});
fabric.Object.prototype.controls.deleteControl = new fabric.Control({
  x: 0.5,
  y: -0.5,
  offsetY: -10,
  offsetX: 10,
  cursorStyle: 'pointer',
  mouseUpHandler: deleteObject,
  render: renderIcon,
})
fabric.Textbox.prototype.controls.deleteControl = new fabric.Control({
  x: 0.5,
  y: -0.5,
  offsetY: -20,
  offsetX: 20,
  cursorStyle: 'pointer',
  mouseUpHandler: deleteObject,
  render: renderIcon,
})
