import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IoAddCircle } from "react-icons/io5";
import {message} from "antd";
import {Link, useNavigate} from "react-router-dom";
import { AxiosResponse } from "axios";
import { useAuth } from "react-oidc-context";

import { TemplateData } from "~typings/types";
import { useAxios } from "~utils/hook";
import { TEMPLATE_DETAIL, TEMPLATE_SEARCH_URL } from "~constants/Routes";
import { decodeUserToken } from "~utils/KeyCloakUtil";
import Loading from "~components/loading-page/Loading";

export default function TabbedDesigner() {
  const [data, setData] = useState<string>("");
  const [filteredTemplate, setFilteredTemplate] = useState<TemplateData[]>();
  const [loadingPage, setLoadingPage] = useState<boolean>(false);
  const navigation = useNavigate();
  const service = useAxios();
  const { t } = useTranslation();

  const auth = useAuth();
  const admin = decodeUserToken(auth.user);

  useEffect(() => {
    setLoadingPage(true);
    service.current?.get(`${TEMPLATE_SEARCH_URL}=${data}`)
      .then((response: AxiosResponse) => {
        setLoadingPage(false);
        if (response.data) {
          setFilteredTemplate(response.data.content);
        } else {
          message.error('No data received');
        }
      }).catch((error) => {
      setLoadingPage(false);
        message.error(error.message || 'An error occurred while fetching templates');
      });
  }, [data, service]);

  const handleFilteredTemplate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setData(e.target.value);
  };

  return (
    <>
      <div className="my-5">
        <div className="row justify-content-center align-items-center">
          <div className={`${admin?.realm_access.roles.some(role => ['Designer', 'Admin'].includes(role)) ? 'col-lg-9' : 'col-lg-12 col-md-12'} col-md-8 col-sm-12 mb-3`}>
            <input
              placeholder={t('search_template')}
              className="form-control rounded-2 w-100"
              value={data}
              onChange={handleFilteredTemplate}
            />
          </div>
          
          {admin?.realm_access.roles.some(role => ['Designer', 'Admin'].includes(role)) && (
            <div className="col-lg-3 col-md-4 col-sm-12 mb-3">
              <div>
                <button
                  type="button"
                  className="background-third-color btn-designer px-3 py-2 text-primary-color rounded-3 d-flex justify-content-center align-items-center w-100"
                  onClick={() => navigation('/template-upload')}
                >
                  <IoAddCircle size={24} className="me-2" />
                  {t('new_template')}
                </button>
              </div>
            </div>
          )}
        </div>
        <section className="d-grid gap-3 mt-2">
          {loadingPage && <Loading/>}
          {!loadingPage &&(
            filteredTemplate && filteredTemplate.length > 0? (
              filteredTemplate?.map((item) => (
                <Link
                  key={item.id}
                  to={`${TEMPLATE_DETAIL}/${item.id}`}
                  className="hover-box p-3 text-decoration-none text-fourth-color shadow-sm col-12 rounded-3 border border-1 border-dark"
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h5>{item.templateName}</h5>
                      <div>{item.description}</div>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p className={'text-center fs-5'}>{t('template_not_found')}</p>
            )
          )}
        </section>
      </div>
    </>
  );
}