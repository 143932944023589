import { Button, Modal, message } from 'antd';
import { t } from 'i18next';
import { useCallback, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FaFileAlt } from 'react-icons/fa';
import { IoCloudUploadSharp } from 'react-icons/io5';
import { MdDelete } from 'react-icons/md';
import { RxCornerBottomLeft, RxCornerBottomRight, RxCornerTopLeft, RxCornerTopRight } from "react-icons/rx";

import { DragDropFileProps } from '~typings/types';

const DragAndDropFile: React.FC<DragDropFileProps> = ({ setFileToNull, handleErrorMessage, accpetedFileType, description, onFileDrop }) => {
  const [isDragging, setIsDragging] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [displayOverlay, setOverlay] = useState<boolean>(false);
  const [file, setFile] = useState<FileList | null>(null);
  const [loadings, setLoadings] = useState<boolean[]>([]);

  const enterLoading = ({ index, isLoading }: { index: number, isLoading: boolean }): any => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = isLoading;
      return newLoadings;
    });
  }

  const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
    setOverlay(true);
  }, []);

  const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    if (!e.relatedTarget) {
      setOverlay(false);
    }
  }, []);

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    setOverlay(false);
    enterLoading({ index: 0, isLoading: true });
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      if (accpetedFileType?.includes(files[0].type)) {
        if (files[0].size > 5 * 1024 * 1024) {
          message.error(t('upload_more_5mb'));
          return;
        }
        onFileDrop(files);
        setFile(files);
      } else {
        if (handleErrorMessage) {
          Modal.error({
            title: handleErrorMessage.title,
            content: handleErrorMessage.description,
            centered: true,
            okButtonProps: {
              className: "background-primay-color"
            },
          })
        }
      }
    }
    enterLoading({ index: 0, isLoading: false });
  }, [onFileDrop]);

  const handleClick = useCallback(() => {
    fileInputRef.current?.click();
  }, []);

  const handleFileChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    enterLoading({ index: 0, isLoading: true });
    const files = e.target.files;
    if (files && files.length > 0) {
      if (files[0].size > 5 * 1024 * 1024) {
        message.error(t('upload_more_5mb'));
        return;
      }
      onFileDrop(files);
      setFile(files);
    }
    enterLoading({ index: 0, isLoading: false });
  }, [onFileDrop]);

  const handleCancelFile = useCallback(() => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    setFile(null);
    setLoadings([false]);
    if (typeof setFileToNull === 'function') {
      setFileToNull(null);
    }
  }, []);

  return (
    <div>
      <div
        onClick={handleClick}
        onDragOver={handleDragOver}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
        className={`${isDragging ? 'bg-white shadow-lg' : "bg-upload"} w-100 h-auto file-drop-hover text-center fs-5 d-flex justify-content-center align-items-center rounded-4`}
      >
        {isDragging ?
          <div className='d-flex focus-ring focus-ring-primary flex-column justify-content-center align-items-center p-5 gap-4'>
            <FaFileAlt size={150} fill='#1C4076' />
            {t('drag_and_droping_description')}
          </div>
          :
          <div className='d-flex focus-ring focus-ring-primary flex-column justify-content-center align-items-center p-5 gap-4'>
            <FaFileAlt size={150} fill='#1C4076' />
            {description}
          </div>
        }
        <input
          type="file"
          ref={fileInputRef}
          style={{ display: 'none' }}
          onChange={handleFileChange}
          accept={accpetedFileType.join(', ')}
        />
        {displayOverlay && <div className='overlay text-white' >
          <RxCornerTopLeft size={60} className='position-absolute top-0 start-0' />
          <RxCornerTopRight size={60} className='position-absolute top-0 end-0' />
          <RxCornerBottomLeft size={60} className='position-absolute bottom-0 start-0' />
          <RxCornerBottomRight size={60} className='position-absolute bottom-0 end-0' />
          <span className='dragging-message fs-3'>Dragging to upload...</span>
        </div>}
      </div>
      <div className='mt-2'>
        {file &&
          <div className="d-flex justify-content-between align-items-center">
            <div>
              {!loadings[0] ? <IoCloudUploadSharp color="#1C4076" size={20} /> :
                <Spinner size="sm" variant="primary" />
              }
              <span className={`${loadings[0] ?? 'opacity-50'}`}> {file[0].name}
                <span className="text-success fw-normal">
                  <span> {t('uploaded_message')}</span>
                </span>
              </span>
            </div>
            <Button type='link' danger onClick={handleCancelFile}><MdDelete className='text-danger' size={25} /></Button>
          </div>
        }
      </div>
    </div>

  );
};

export default DragAndDropFile;