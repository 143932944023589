import React from 'react';
import { Popover } from 'antd';
import { MdTipsAndUpdates } from 'react-icons/md';

import { ToolTipContent } from '~typings/types';

const Content: React.FC<ToolTipContent> = ({ textContent }) => {
  return (
    <div>
      <p>{textContent}</p>
    </div>
  );
};

const ToolTips: React.FC<ToolTipContent> = ({ textContent, color }) => (
  <Popover content={<Content textContent={textContent} color={undefined} />}>
    <MdTipsAndUpdates color={color} cursor={'pointer'} />
  </Popover>
);

export default ToolTips;
