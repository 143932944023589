import { LoadingOutlined } from '@ant-design/icons';
import { Space, Spin } from 'antd';

const Loading = ({addedClassName}:{addedClassName?: string}) => {

  return (
    <Space className={`d-flex justify-content-center align-items-center ${addedClassName}`}>
      <Spin indicator={<LoadingOutlined  spin className={"loading my-3"}/>}/>
    </Space>
  )
}
export default Loading;