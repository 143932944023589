import { Button } from 'react-bootstrap';
import { useAuth } from 'react-oidc-context';
import { useTranslation } from 'react-i18next';
import Dropdown from 'react-bootstrap/Dropdown';
import { CgLogOut } from "react-icons/cg";
import { useEffect, useState } from 'react';
import { BiUser } from 'react-icons/bi';

const AuthButton = () => {
  const auth = useAuth();
  const { t } = useTranslation();
  const [username, setUsername] = useState<String | undefined>(undefined);

  useEffect(() => {
    setUsername(auth.user?.profile.name);
  }, [auth]);

  const handleClick = () => {
    if (auth.isAuthenticated) {
      auth.signoutRedirect();
    } else {
      auth.signinRedirect();
    }
  };

  return (
    <>
      {
        auth.isAuthenticated ? (
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className='bg-transparent border-0 dropdown-nav text-primary-color d-flex gap-2 justify-content-center align-items-center custom-style'>
              <div className="btn rounded-5 text-uppercase background-primay-color text-white fw-semibold text-primary-color btn-text-color btn-designer">
                {username?.split('')[0] || <BiUser />}
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <div className='px-3 d-flex justify-content-between align-items-center gap-3'><BiUser /> {auth.user?.profile.email || 'Anonymous'}</div>
              <Dropdown.Divider />
              <Dropdown.Item onClick={handleClick} className='d-flex gap-3 justify-content-between align-items-center'>{t('logout')} <CgLogOut size={20} /></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          <Button
            onClick={handleClick}
            className="text-white border-0 background-primay-color py-2 rounded-3 login-button"
          >
            <p className={'d-md-block d-none mb-0'}>{t('login')}</p>
            <BiUser size={24} className={'text-white d-md-none d-block'} />
          </Button>
        )
      }
    </>
  );
};

export default AuthButton;