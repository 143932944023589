import { User, WebStorageStateStore } from "oidc-client-ts";

const onSigninCallback = (_user: User | void): void => {
  window.history.replaceState( {}, document.title, window.location.pathname )
}

export const getCurrentUrl = ():string => {
  return window.location.href;
}

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY_URL,
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID,  
  redirect_uri: getCurrentUrl(),
  userStore: new WebStorageStateStore({ store: window.localStorage }), // Store the user in local storage
  onSigninCallback, 
  post_logout_redirect_uri: window.location.origin    // Redirect to the home page after logout
};

export default oidcConfig;