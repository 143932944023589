export const ReadJsonFile = (jsonFile: FileList): Promise<any> => {
  return new Promise((resolve, reject) => {
    if (!jsonFile || jsonFile.length === 0) {
      reject(new Error("No file selected."));
      return;
    }

    const file = jsonFile[0];

    if (file.type !== 'application/json') {
      reject(new Error("Invalid file type. Please select a JSON file."));
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      try {
        const jsonData = JSON.parse(event.target?.result as string);
        resolve(jsonData);
      } catch (error:any) {
        reject(new Error("Error parsing JSON: " + error.message));
      }
    };

    reader.onerror = (error:any) => {
      reject(new Error("Error reading file: " + error.message));
    };

    reader.readAsText(jsonFile[0]);
  });
};
