import { MdEmail } from "react-icons/md";
import { FaFacebookF, FaLocationDot } from "react-icons/fa6";
import { FaTelegramPlane, FaYoutube } from "react-icons/fa";
import { BiLogoInstagramAlt } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { FaTwitter } from "react-icons/fa6";

import dgc from '~assets/logo/DGC-LOGO.svg';
import mptc from '~assets/logo/mptc.png';
import logo from '~/assets/logo/Likhet_Vertical_White.svg';
import { PRIVACY_POLICY_PAGE, TERMS_PAGE } from "~constants/Routes"

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className='background-primay-color px-lg-5 px-md-3 px-2 py-md-5 py-3 d-grid gap-5'>
      <div className={'d-lg-flex flex-md-row flex-column gap-4 justify-content-between align-items-start text-white '}>
        <div>
          <Link className='text-decoration-none w-100 text-white' to='/'>
            <img src={logo} alt={'logo'} className="footer-logo mx-auto" />
          </Link>
          <div className='d-flex mt-4 gap-5 justify-content-start align-items-center'>
            <div className='fs-2 fw-medium '>{t('by')}</div>
            <div className='d-flex gap-2'>
              <Link target='_blank' className='text-decoration-none text-white' to='https://mptc.gov.kh/en/'>
                <img src={mptc} alt={'mptc - logo'} className={'object-fit-cover'} style={{ width: '50px', height: '50px' }} />
              </Link>
              <Link target='_blank' className='text-decoration-none text-white' to='https://dgc.gov.kh/'>
                <img src={dgc} alt={'dgc - logo'} className={'object-fit-cover'} style={{ width: '50px', height: '50px' }} />
              </Link>
            </div>
          </div>
        </div>
        <div>
          <strong>{t('contact')}</strong>
          <div className='d-grid gap-2 mt-3'>
            <Link to="https://mptc.gov.kh/en/" className="text-white text-decoration-none"><MdEmail color='white' size={20} /> info@likhet.gov.kh</Link>
            <Link target='_blank' to="https://www.google.com/maps/place/Ministry+of+Post+and+Telecommunications/@11.5752683,104.9148065,17z/data=!4m6!3m5!1s0x31095142eadcf8db:0xbd4e32a5eccddfb7!8m2!3d11.5752627!4d104.9173786!16s%2Fg%2F1yfdrw680?entry=ttu" className="text-white text-decoration-none"><FaLocationDot color='white' size={20} /> {t('footer_address')}</Link>
          </div>
        </div>
        <div className='d-grid gap-1'>
          <Link target='_blank' className='text-decoration-none text-white' to={PRIVACY_POLICY_PAGE} >{t('privacy')}</Link>
          <Link target='_blank' className='text-decoration-none text-white' to={TERMS_PAGE}>{t('term_of_use')}</Link>
        </div>
        <div className='d-grid gap-4 mt-lg-0 mt-md-2 mt-3'>
          <div className='d-flex gap-3'>
            <Link target='_blank' to='https://www.facebook.com/www.mptc.gov.kh'><FaFacebookF size={20} color='white' /></Link>
            <Link target='_blank' to='https://t.me/mptcgovkh'><FaTelegramPlane size={20} color='white' /></Link>
            <Link target='_blank' to='https://www.youtube.com/c/MPTCMedia'> <FaYoutube size={20} color='white' /></Link>
            <Link target='_blank' to='https://www.instagram.com/mptc.gov.kh/'> <BiLogoInstagramAlt size={20} color='white' /></Link>
            <Link target='_blank' to='https://x.com/mptcgovkh'> <FaTwitter size={20} color='white' /></Link>
          </div>
        </div>
      </div>
      <div style={{ width: '100%', height: '1px', backgroundColor: 'white' }} ></div>
      <p className='text-white'>{t('digital_government')}</p>
    </footer>
  )
}