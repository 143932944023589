import { Button, Form, Radio, RadioChangeEvent } from "antd";
import { t } from "i18next";
import { CgRemove } from "react-icons/cg";

import { RadioButtonOptions } from "~typings/types";

export default function RadioButtons({ item, keyPage, subKey, updateRadio }: RadioButtonOptions) {  
  const onChange = (e: RadioChangeEvent) => {
    const nestedKey = options.find(option => option.value === e.target.value)?.nestedKey;
    updateRadio({
      key: keyPage,
      subKey: subKey,
      nestedKey: nestedKey,
      newValue: e.target.value ? true : false
    });
  };

  const options = Object.keys(item.nested_item)
    .sort((a, b) => item.nested_item[a].id - item.nested_item[b].id)
    .map(key => ({
      label: `${item.nested_item[key].display_section || "Untitled"}`,
      value: key,
      nestedKey: key,
      checked: item.nested_item[key].value === 'true'
    }));

  const defaultOption = options.find(option => option.checked);

  const resetSelection = () => {
    updateRadio({
      key: keyPage,
      subKey: subKey,
      nestedKey: options.find(option => option.value)?.nestedKey,
      newValue: "false"
    });
  };

  return (
    <Form.Item
      initialValue={defaultOption?.value}
    >
      <Radio.Group value={defaultOption?.value} onChange={onChange} className="mb-3 w-100">
        {options.map(option => (
          <Radio value={option.value} key={option.value} className="fs-6">{option.label}</Radio>
        ))}
      <Button icon={<CgRemove />} type="link" danger className="my-1 fs-6" onClick={resetSelection}>{t('remove')}</Button>
      </Radio.Group>
    </Form.Item>
  );
}
