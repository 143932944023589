import {Link} from "react-router-dom";
import {TbExternalLink} from "react-icons/tb";
import SEO from "~components/seo/SEO";

export const TermsPage = () => {
  return (
    <div className={"d-flex justify-content-center m-auto privacy-policy"}>
      <SEO title={"Terms - Likhet"} description={"Write Government Letters Efficiently through form!"} />
      <div>
        <h1 className={"py-4 title-privacy-policy"}>Terms of Use</h1>
        <ol>
          <li className={"terms-title"}>General</li>
          <ul>
            <li className={"terms-description ps-3 py-3"}>Thank you for visiting <Link to={"https://likhet.gov.kh/"}
                                                                                      className={"url-contact-dgc"}>https://likhet.gov.kh <TbExternalLink/></Link> (“website").
              Government Likhet website is officially operated
              by the Digital Government Committee. Your access and use of this website constitute your acceptance and
              agreement to be bound by these terms and conditions of use (“Terms of Use”). If you do not consent to be
              bound by these Terms of Use, please discontinue your access and do not use this website.
            </li>
            <li className={"terms-description ps-3 pb-3"}>We keep the right to change these Terms of Use without prior
              notice. The changed Terms of Use will be
              posted on this website.
            </li>
          </ul>
          <li className={"terms-title"}>Copyrights</li>
          <ul>
            <li className={"terms-description ps-3 py-3"}>All copyrights on this website are owned by, licensed to, or
              controlled by the Digital Government
              Committee.
            </li>
            <li className={"terms-description ps-3 pb-3"}>No material or contents, including without limitation text,
              images, music, computer programs, and all
              other kinds of works, on this website may be used, adapted, uploaded to a third party, linked to, framed,
              performed in public, distributed or transmitted in any form by any process without the specific written
              consent of the Digital Government Committee.
            </li>
          </ul>
          <li className={"terms-title"}>Availability and Right of Access</li>
          <ul>
            <li className={"terms-description ps-3 py-3"}>The Digital Government Committee reserves the right to update
              or modify this website from time to time.
              The Digital Government Committee might stop the operation of the website for any period of time without
              any prior notice.
            </li>
            <li className={"terms-description ps-3 pb-4"}>The Digital Government Committee has the right to deny or
              block access to this website by any person or
              IP address at any time without prior notice and without ascribing any reasons whatsoever.
            </li>
            <li className={"terms-description ps-3 pb-4"}>This website, material, and contents may be modified, deleted,
              edited, or removed by the Digital
              Government Committee at any time without prior notice.
            </li>
            <li className={"terms-description ps-3 pb-4"}>You agree that you do not do any action to monitor or copy any
              pages or contents within this website
              without prior written permission by the Digital Government Committee.
            </li>
          </ul>
          <li className={"terms-title"}>Links to other websites</li>
          <ul>
            <li className={"terms-description ps-3 py-4"}>The website may contain links to third party websites. You
              should understand and accept that the Digital
              Government Committee does not endorse, or respond to the operators of third party websites; the
              information, graphics, and material on those websites.
            </li>
            <li className={"terms-description ps-3 pb-4"}>The Digital Government Committee has no control over and
              cannot guarantee the accessibility or proper
              functioning of those websites. The Digital Government Committee shall not be liable for any damage or loss
              whatsoever arising from your access to such other websites. Use of such links and access to those websites
              are entirely at your own risk.
            </li>
            <li className={"terms-description ps-3 pb-4"}>Digital Government Committee has reserved the right to remove
              or disable any unauthorized links to or
              any unauthorized content appearing on any other website.
            </li>
          </ul>
          <li className={"terms-title"}>Creating a link to the Government Likhet website</li>
          <ul>
            <li className={"terms-description ps-3 py-4"}>Please, contact the Digital Government Committee in written
              form if you would like to place a link to
              any part of the Government Likhet website.
            </li>
          </ul>
        </ol>
        <p>These Terms of Use are dated 01 April 2024.</p>
      </div>
    </div>
  )
}